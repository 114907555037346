import { configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';

import rootReducer from './root-reducer';


let middlewares = "";

if (process.env.NODE_ENV === 'development') {
    middlewares = logger;
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => process.env.NODE_ENV === 'development' ? getDefaultMiddleware({
        serializableCheck: false,
    }).concat(middlewares) : getDefaultMiddleware({
        serializableCheck: false,
    })
})

export const persistor = persistStore(store);

//export default { store, persistor };

