/* 
JWT Auth Token for connected to the api
*/
export const jwt_secret = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_JWT : process.env.REACT_APP_JWT;

/* 
GOOGLE KEY for connected to the api
*/
export const google_secret = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_GOOGLE_KEY : process.env.REACT_APP_GOOGLE_KEY;

/* 
Base urls for api and sites
*/
export const BASEURLAPI = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASEURLAPI : process.env.REACT_APP_BASEURLAPI;
export const BASEURLREGGIO = process.env.REACT_APP_BASEURLREGGIO;
export const BASEURLAPIIMAGES = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASEURLAPIIMAGES : process.env.REACT_APP_BASEURLAPIIMAGES;

//AUTH URL
export const AUTH = `${BASEURLAPI}/${process.env.REACT_APP_AUTH}`;

//START URL
export const START = `${BASEURLAPI}/${process.env.REACT_APP_START}`;

//USER URL
export const USER = `${BASEURLAPI}/${process.env.REACT_APP_USER}`;

//PERMISSIONS URL
export const PERMISSIONS = `${BASEURLAPI}/${process.env.REACT_APP_PERMISSIONS}`;

//ROLES URL
export const ROLES = `${BASEURLAPI}/${process.env.REACT_APP_ROLES}`;

//CATEGORIES URL
export const CATEGORIES = `${BASEURLAPI}/${process.env.REACT_APP_CATEGORIES}`;

//KID URL
export const KID = `${BASEURLAPI}/${process.env.REACT_APP_KID}`;

//CONTACT URL
export const CONTACT = `${BASEURLAPI}/${process.env.REACT_APP_CONTACT}`;

//TRANSACTION URL
export const TRANSACTION = `${BASEURLAPI}/${process.env.REACT_APP_TRANSACTION}`;

//ROUTE MAP URL
export const ROUTE_MAP = `${BASEURLAPI}/${process.env.REACT_APP_ROUTE_MAP}`;

//CLOSED DAYS URL
export const CLOSED_DAYS = `${BASEURLAPI}/${process.env.REACT_APP_CLOSED_DAYS}`;

//DAYS OFF URL
export const DAYS_OFF = `${BASEURLAPI}/${process.env.REACT_APP_DAYS_OFF}`;

//LEAVE OF ABSENCE URL
export const ABSENCE = `${BASEURLAPI}/${process.env.REACT_APP_ABSENCE}`;

//CALENDAR URL
export const CALENDAR = `${BASEURLAPI}/${process.env.REACT_APP_CALENDAR}`;

//Headers for applications requests
export const HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Accept':'application/json',
};