import { createGlobalStyle } from 'styled-components';
import {
  colorBlue,
  colorBlueHover,
  colorText,
  colorAdditional,
  colorAccent,
  colorAccentHover,
  colorWhite,
  colorRed,
  colorBackgroundBody,
  scrollbarStyles,
  colorBorder
} from '../../utils/palette';
import {
  left,
  borderLeft,
  paddingLeft,
} from '../../utils/directions';



const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  main {
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0 !important;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 1.6;
  }

  .row > * {
    padding: 0 15px;
  }

  * {
    box-sizing: inherit;
  }

  ul, ol {
    // doesn't do RTL, it break a sidebar
    padding-left: 15px;
    margin-bottom: 0;
    list-style-image: url(${process.env.PUBLIC_URL}/img/circle.svg);
  }
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type=number] {
      -moz-appearance: textfield;
  }

  a {
    color: ${colorBlue};
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      color: ${colorBlueHover};
    }
  }

  button:focus, button:active {
    outline: none;
  }

  img {
    width: 100%;
  }

  label {
    margin-bottom: 8px;
  }

  //TYPOGRAPHY
  p, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 0;
    color: ${colorText};
  }

  h1 {
    font-size: 36px;
    line-height: 48px;

    &.subhead {
      font-size: 30px;
      color: ${colorAdditional};
      line-height: 36px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 36px;

    &.subhead {
      font-size: 24px;
      color: ${colorAdditional};
      line-height: 32px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 32px;

    &.subhead {
      font-size: 18px;
      color: ${colorAdditional};
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;

    &.subhead {
      font-size: 12px;
      color: ${colorAdditional};
      line-height: 16px;
    }
  }

  h5 {
    font-size: 14px;
    line-height: 18px;

    &.subhead {
      font-size: 10px;
      color: ${colorAdditional};
      line-height: 12px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 16px;

    &.subhead {
      font-size: 8px;
      color: ${colorAdditional};
      line-height: 10px;
    }
  }
  
  hr {
    opacity: 0.1;
  }
  
  .table-responsive {
    ${scrollbarStyles};
  }

  blockquote {
    font-size: 13px;
    font-style: italic;
    ${borderLeft}: 3px solid ${colorAccent};
    margin-bottom: 0;
    text-align: ${left};
    ${paddingLeft}: 10px;
  }

  .highlight {
    background-color: ${colorAccent};
    color: ${colorWhite};
  }

  .typography-message{
    text-align: ${left};
  }

  .red-text {
    color: ${colorRed};
  }

  .page-title {
    text-align: ${left};
    font-weight: 500;
    text-transform: capitalize;
    font-size: 20px;

    &:last-child {
      margin-bottom: 30px;
    }

    &.page-title--not-last {
      margin-top: 20px;
    }
  }

  .page-subhead {
    text-align: ${left};
    margin-bottom: 20px;

    &.subhead {
      font-size: 14px;
      opacity: 0.7;
    }
  }

  ::selection {
    color: ${colorWhite};
    background: ${colorAccent};
  }

  .first-letter-uppercase:first-letter {
    text-transform: uppercase;
  }

  .container {

    @media screen and (min-width: 768px) {
      width: 100%;
      max-width: 1630px;
    }
  }


  .rc-slider-tooltip {
    padding-bottom: 2px;

    .rc-slider-tooltip-inner {
      margin-bottom: 5px;
      background-color: ${colorBackgroundBody};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      color: ${colorText};
      font-size: 12px;
      line-height: 12px;
      padding: 4px 4px;
      height: 20px;
      min-width: 28px;
      border-radius: 0;
    }

    &.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      border-top-color: ${colorBackgroundBody};
    }

    &.rc-slider-tooltip-hidden {
      display: block;
    }
  }

  #profileImage {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background: #E1E2EB;
      font-size: 12px;
      color: #707070;
      text-align: center;
      padding: 8px 0;
      font-weight: 600;
      display: inline-block;
  }

  .header-profile-user {
      height: 36px;
      width: 36px;
      background-color: ${colorBorder};
      padding: 3px;
  }

  .rounded-circle {
      border-radius: 50%!important;
  }
  /* *********************** Profile Image ************************** */

  .profile-image-component {
      display: flex;
      flex-direction: column;
      margin: 0px auto 0px auto;
      border-radius: 50%;
      width: 150px;
      height: 150px;
      border: 0;
  }

  .profile-image-component::after {
    display: none;
  }

  .profile-image-component:hover {
      background: #3f3f3f;
  }

  .profile-image-component:hover .change-picture-hover {
      opacity: 1 !important;
      cursor: pointer;
  }

  .profile-image-component:hover .hover {
      opacity: 0.3 !important;
      cursor: pointer;
  }

  .profile-image-component .hover-wrapper {
      width: 151px;
      height: 151px;
  }

  .profile-image-component .hover-wrapper .profile-image {
      opacity: 1;
      width: 151px;
      height: 150px;
      border-radius: 50%;
      transition: 0.3s ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      object-fit: cover;
  }

  .profile-image-component .hover-wrapper .change-picture-hover {
      transition: 0.3s ease;
      opacity: 0;
      position: relative;
      bottom: 60%;
      left: 0;
      text-align: center;
  }

  .profile-image-component .hover-wrapper .change-picture-hover i {
      font-size: 45px;
      text-align: center;
      line-height: 0;
      padding-left: 4px;
      color: white;
  }

  .profile-image-component .hover-wrapper .change-picture-hover .change-picture-text {
      color: white;
      font-size: 18px;
      margin: 0px 10px;
      line-height: 25px;
      text-align: center;
  }

  /* *********************** Profile Image ************************** */

  /* *********************** Files Modal ************************** */

.file-remove {
    display: inline;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.6;

    &.disabled{
      opacity: 0.2;
      cursor: not-allowed;
    }
}

.file-remove:hover {
    opacity: 1.0;
    color: #c43d4b;

    &.disabled{
      opacity: 0.2;
      color: inherit;
    }
}

.file-show, .file-download {
    display: inline;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.6;
}

.file-show:hover, .file-download:hover {
    opacity: 1.0;
    color: rgb(76, 175, 80);
}

.file-show.active, .file-download.active {
    opacity: 0.6 !important;
    color: #70bbfd !important;
    cursor: not-allowed !important;
}

.img-files {
    width: 24px;
    position: relative;
    top: -1px;
}

.custom-file-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: inherit;
}

.custom-file-input {
    z-index: inherit;
}

/* *********************** Files Modal ************************** */

.bm-overlay{
  top: 60px !important;
  right: 0 !important;
}

/* Calendar */
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-today-button, .fc .fc-dayGridMonth-button, .fc .fc-dayGridWeek-button, .fc .fc-dayGridDay-button{
  color: ${colorText} !important;
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 14px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.fc .fc-today-button:not(:disabled):hover, .fc .fc-dayGridMonth-button:not(:disabled):hover, 
.fc .fc-dayGridWeek-button:not(:disabled):hover, .fc .fc-dayGridDay-button:not(:disabled):hover{
  color: ${colorAccentHover} !important;
}

.fc .fc-today-button:not(:disabled):active, .fc .fc-dayGridMonth-button:not(:disabled):active, 
.fc .fc-dayGridWeek-button:not(:disabled):active, .fc .fc-dayGridDay-button:not(:disabled):active{
  color: ${colorWhite} !important;
}

.fc .fc-button-primary:disabled, .fc .fc-button-primary:not(:disabled).fc-button-active{
  color: ${colorWhite} !important;
  background-color: ${colorAccent} !important;
  border-color: ${colorAccent} !important;
  opacity: 1 !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}

.fc .fc-filters-button, .fc .fc-event-button {
  color: ${colorWhite} !important;
  background-color: ${colorAccent} !important;
  border-color: ${colorAccent} !important;
  opacity: 1 !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
  position: relative !important;
  font-size: 14px !important;
}  

.fc .fc-filters-button:before, .fc .fc-event-button:before {
  content: url(../../assets/img/search.svg);
  position: absolute;
  left: 12px;
  top: 8px;
}

.fc .fc-toolbar-title {
  color: ${colorText} !important;
  font-weight: 400 !important;
}

.fc .fc-col-header-cell-cushion {
  color: ${colorText} !important;
  padding: 15px 0px;
}

.fc .fc-daygrid-day-number {
  color: ${colorText} !important;
}

.fc-prev-button, .fc-next-button {
  color: ${colorWhite} !important;
  background-color: ${colorAccent} !important;
  border-radius: 50% !important;
  padding: 8px 9px !important;
  border-color: transparent !important;
  margin-right: 10px !important;
}

.fc-prev-button:hover, .fc-next-button:hover, .fc-newOrder-button:hover{
  background-color: ${colorAccentHover} !important;
}

.fc-prev-button:active, .fc-next-button:active, .fc-newOrder-button:active{
  background-color: ${colorAccentHover} !important;
}

.fc-theme-standard .fc-scrollgrid, .fc-theme-standard td, .fc-theme-standard th{
  border: 1px solid ${colorBorder};
  border: 1px solid ${colorBorder};
  border: 1px solid var(--fc-border-color, ${colorBorder});
}

// .fc-theme-standard th{
//   padding-top: 15px !important;
//   padding-bottom: 15px !important;
// }

th.fc-day-today{
  color: ${colorWhite} !important;
  background-color: ${colorAccent} !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(152 158 179,.1) !important;
}

.fc-daygrid-day{
  color: ${colorAccent} !important;
  background-color: transparent !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.fc-daygrid-day.fc-day-today .fc-daygrid-day-number{
  color: ${colorWhite} !important;
  background-color: ${colorAccent} !important;
  opacity: 1 !important;
  font-weight: 600;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px 0px !important;
  border-color: transparent !important;
  border-top-right-radius: 22px !important;
  border-bottom-right-radius: 22px !important;
  border-top-left-radius: 22px !important;
  border-bottom-left-radius: 22px !important;
  font-size: 14px !important;
}

.popover, .tooltip{
  z-index: 10000;
}

.fc-more-popover{
  z-index: 10000 !important;
  min-width: 300px !important;
}

.fc-more-popover .fc-popover-body  {
  max-height: 400px;
  overflow-y: scroll;
}

.fc-theme-standard .fc-popover{
  border: 1px solid #F1F1F7 !important;
  border-radius: 4px !important;
}

.fc .fc-popover-header{
  color: #145388 !important;
  background-color: #F1F1F7 !important;
  font-weight: 600;
  padding: 10px !important;
  font-size: 14px !important;
}

.fc-h-event{
  border: 1px transparent !important;
  background-color: transparent !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 1px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 1px !important;
}

.fc-event, .fc-event-dot {
  background-color: transparent;
}

@media screen and (max-width: 1125px) {
    .fc-toolbar-title{
      font-size: 16px !important;
      position: absolute;
      top: 10px;
      right: 30px;
    }

    .fc-toolbar-chunk{
      width: 100% !important
    }

    .fc .fc-toolbar {
      display: block !important;
    }

    .fc-button-group{
      position: absolute !important;
      top: 10px;
    }

    .fc-toolbar-chunk:last-child{
      margin-top: 15px;
    }
}

@media screen and (min-width: 525px) {
      
}

/* Calendar */
`;

export default GlobalStyles;
