import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, CALENDAR } from '../../constants/defaultValues';

const startFetchSearchFields = createAsyncThunk('calendar/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CALENDAR}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCalendar = createAsyncThunk('calendar/startFetchCalendar', async ({ locale, token, filter }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CALENDAR}?
        &start=${filter.start}
        &end=${filter.end}
        &category=${filter.filter.category.length > 0 ? filter.filter.category.join() : ""}
        &user=${filter.filter.user.length > 0 ? filter.filter.user.join() : ""}
    
        &fromDate_type=${filter.filter.fromDate_type}
        &toDate_type=${filter.filter.toDate_type}
        `, { headers });

        return res.data
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCalendarInfo = createAsyncThunk('calendar/startCalendarInfo', async ({ id, category, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CALENDAR}/info/${id}/${category}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateEvent = createAsyncThunk('calendar/startCreateEvent', async ({ eventData, locale, token, events }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CALENDAR}/storeEvent`, eventData, { headers });

        let new_data = JSON.parse(JSON.stringify(events));

        let returnData = res.data.event
        returnData.id = new_data.data.length
        returnData.index = new_data.data.length

        new_data.data.push(returnData);
        new_data.totalItemCount = new_data.totalItemCount + 1;

        dispatch(calendarActions.fetchCalendarSuccess(new_data))

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateEvent = createAsyncThunk('calendar/startUpdateEvent', async ({ id, eventData, locale, token, events, index }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CALENDAR}/updateEvent/${id}`, eventData, { headers });

        let new_data = JSON.parse(JSON.stringify(events));

        let returnData = res.data.event
        returnData.id = index
        returnData.index = index
        
        new_data.data[index] = returnData;
        
        dispatch(calendarActions.fetchCalendarSuccess(new_data))

        return res.data.status;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteEvent = createAsyncThunk('calendar/startDeleteEvent', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CALENDAR}/destroyEvent/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    events: {
        data: [],
        totalItemCount: 0
    },
    searchFields: {
        users: [],
        categories: []
    },
    searchFilter: {
        user: [],
        category: [],

        fromDate_type: "",
        toDate_type: "",
    },
    calendarInfo: {
        id: '',
        title: '',
        message: '',
        user: '',
        start: '',
        end: '',
        category: ''
    },
    createEvent: '',
    updateEvent: '',
    deleteEvent: '',
    loadingBtn: false,
    loadingModal: false,
    loading: true,
    error: ''
};

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        clearCalendar(state) {
            state.loadingBtn = false
            state.loadingModal= false
            state.loading= false
            state.error= ''
        },
        clearAlertCalendar(state) {
            state.calendarInfo = initialState.calendarInfo
            state.error= ''
            state.createEvent= ''
            state.updateEvent= ''
            state.deleteEvent= ''
        },
        fetchCalendarSuccess(state, { payload }) {
            return {...state, events: payload}
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilter: payload }
        },
        clearFilter(state) {
            return { ...state, searchFilter: initialState.searchFilter }
        }
    },
    extraReducers: {
      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, error: '', searchFields: payload };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, error: payload, searchFields: initialState.searchFields };
      },

      [startFetchCalendar.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCalendar.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, error: '', events: payload };
      },
      [startFetchCalendar.rejected]: (state, { payload }) => {
        return { ...state, loading: false, error: payload, events: initialState.events };
      },

      [startCalendarInfo.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startCalendarInfo.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, error: '', calendarInfo: payload };
      },
      [startCalendarInfo.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, error: payload, calendarInfo: '' };
      },

      [startCreateEvent.fulfilled]: (state, { payload }) => {
        return { ...state, createEvent: payload, error: '' };
      },
      [startCreateEvent.rejected]: (state, { payload }) => {
        return { ...state, createEvent: '', error: payload };
      },

      [startUpdateEvent.fulfilled]: (state, { payload }) => {
        return { ...state, updateEvent: payload, error: '' };
      },
      [startUpdateEvent.rejected]: (state, { payload }) => {
        return { ...state, updateEvent: '', error: payload };
      },

      [startDeleteEvent.fulfilled]: (state, { payload }) => {
        return { ...state, deleteEvent: payload, error: '' };
      },
      [startDeleteEvent.rejected]: (state, { payload }) => {
        return { ...state, deleteEvent: '', error: payload };
      },
    },
});

export const calendarActions = {
    ...calendarSlice.actions,
    startFetchSearchFields,
    startFetchCalendar,
    startCalendarInfo,
    startCreateEvent,
    startUpdateEvent,
    startDeleteEvent
}
export default calendarSlice.reducer