export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const setErrorAction = errors => {
  let errorValue = "";

  if (typeof errors === 'string' || errors instanceof String) {
    errorValue = errors.result;
  } else {
    for (const prop in errors) {
      if (errors.hasOwnProperty(prop)) {
        errorValue += errors[prop];
      }
    }
  }

  return errorValue;
};


export const getFormattedPhoneNumber = phone => {
  if (phone) {
    let codeAndPhone = phone.split(" ");

    return codeAndPhone[0].replace("00", "+") + ' ' + codeAndPhone[1];
  }
};

export const formatMoney = (n, currency = '') => {
  let money = Number(n);

  return currency + " " + money.toFixed(0).replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
  });
};

export const vatAmount = (nGrossAmount,nVatRate) => {
  let vat = nVatRate / 100;
  return nGrossAmount / (1 / vat);
};

export const returnNameDatafields = (data, id, key = "name") => {
  try{
    const selected = data.filter(option => option.id === id)
    return selected[0][key]
  }catch(error){
    return ""
  }
};

export const pageButtonRenderer = ({page, active, onPageChange}) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    return (
      <li className={`page-item ${active ? "active" : null}`}>
        {typeof page === 'string' ? 
        <a href="/#" onClick={ handleClick } className="page-link page-symbol">
          {page === '<<' ? 
            <i className='bx bxs-chevrons-left' ></i>
          : null}
          {page === '<' ? 
            <i className='bx bxs-chevron-left' ></i>
          : null}
          {page === '>>' ? 
            <i className='bx bxs-chevrons-right' ></i>
          : null}
          {page === '>' ? 
            <i className='bx bxs-chevron-right' ></i>
          : null}
        </a>
        : 
          <a href="/#" onClick={ handleClick } className="page-link">{ page }</a>
        }
      </li>
    );
  };




