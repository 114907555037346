import React, { Component } from 'react';
import MainWrapper from './containers/App/MainWrapper';

import { Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { authUserActions } from './redux/auth/authUserSlice';
import jwt from 'jsonwebtoken';
import { jwt_secret, AUTH, USER } from './constants/defaultValues';

const ViewMain = React.lazy(() =>
  import(/* webpackChunkName: "views" */ './views')
);
const ViewApp = React.lazy(() =>
  import(/* webpackChunkName: "views-app" */ './views/app')
);
const ViewUser = React.lazy(() =>
  import(/* webpackChunkName: "views-user" */ './views/user')
);
const ViewError = React.lazy(() =>
  import(/* webpackChunkName: "views-error" */ './views/error')
);

const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
       return authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${process.env.PUBLIC_URL}/user/login`,
              state: { from: props.location }
            }}
          />
        )
        }
      }
    />
  );
}

class Router extends Component {

  componentDidMount() {
    const { author, loginAuthorError } = this.props;

    if (author) {
      let token = author.access_token;

      if (token) {
        jwt.verify(token, jwt_secret, (err, decoded) => {
          if (err) {
            loginAuthorError('Your session expired.')
          } else {
            //(decoded.iss);
            if ((decoded.iss !== `${AUTH}/login`) && (decoded.iss !== `${AUTH}/refreshToken`) && !decoded.iss.includes(`${USER}/update/`)) {
              loginAuthorError('Your session expired.')
            }
          }
        });
      }
    } else {
      loginAuthorError('')
    }
  }

  render() {
    const { author } = this.props;

    return (
        <MainWrapper>
          <main>
            <Switch>
              <AuthRoute
                path="/app"
                authUser={author}
                component={ViewApp}
              />
              <Route
                path="/user"
                render={props => <ViewUser {...props} />}
              />
              <Route
                path="/error"
                exact
                render={props => <ViewError {...props} />}
              />
              <Route
                path="/"
                exact
                render={props => <ViewMain {...props} />}
              />
              <Redirect to="/error" />
            </Switch>
          </main>
        </MainWrapper>
    );
  }
}

const mapStateToProps = ({ authUser }) => {
  const { author } = authUser;
  return { author };
};

const mapDispatchToProps = dispatch => ({
  loginAuthorError: message => dispatch(authUserActions.loginAuthorError(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Router);
