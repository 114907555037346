import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, TRANSACTION } from '../../constants/defaultValues';
import moment from 'moment';

const startFetchTransactions = createAsyncThunk('transaction/startFetchTransactions', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let dataField = "";
        let order = "";

        if (filter.sortObj) {
            dataField = filter.sortObj.dataField;
            order = filter.sortObj.order;
        }

        const res = await axios.get(`${TRANSACTION}?pageSize=${filter.pageSize}&dataField=${dataField}&order=${order}&currentPage=${filter.currentPage}&search=${filter.search}
        &f_inc_or_exp=${filter.filter.inc_or_exp}&f_create_date_from=${filter.filter.create_date_from}&f_create_date_to=${filter.filter.create_date_to}
        &f_payment_date_from=${filter.filter.payment_date_from}&f_payment_date_to=${filter.filter.payment_date_to}&f_complete=${filter.filter.complete}
        &f_payment=${filter.filter.payment}&f_basic=${filter.filter.basic}&f_secondary=${filter.filter.secondary}&f_specific=${filter.filter.specific}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('transaction/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchBasicFieldsList = createAsyncThunk('transaction/startFetchBasicFieldsList', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/basicField?id=${id}&edit=${0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSecondaryFieldsList = createAsyncThunk('transaction/startFetchSecondaryFieldsList', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/secondaryField?id=${id}&edit=${0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSpecificFieldsList = createAsyncThunk('transaction/startFetchSpecificFieldsList', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/specificField?id=${id}&edit=${0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateTransaction = createAsyncThunk('transaction/startCreateTransaction', async ({ transaction, locale, token, form, setPage, setTabs }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TRANSACTION}/store`, transaction, { headers });

        if(res.data){
            form.restart();
            setPage(1);
            setTabs([false, true, true, true]);
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateTransaction = createAsyncThunk('transaction/startUpdateTransaction', async ({ id, transaction, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${TRANSACTION}/update/${id}`, transaction, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteTransaction = createAsyncThunk('transaction/startDeleteTransaction', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${TRANSACTION}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const startFetchDataFields = createAsyncThunk('transaction/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchBasicFields = createAsyncThunk('transaction/startFetchBasicFields', async ({ locale, token, ifEdit, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/basicField?id=${id}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSecondaryFields = createAsyncThunk('transaction/startFetchSecondaryFields', async ({ locale, token, ifEdit, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/secondaryField?id=${id}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSpecificFields = createAsyncThunk('transaction/startFetchSpecificFields', async ({ locale, token, ifEdit, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/specificField?id=${id}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactFields = createAsyncThunk('transaction/startFetchContactFields', async ({ locale, token, ifEdit, keywords }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/selectContacts?keywords=${keywords}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchKidFields = createAsyncThunk('transaction/startFetchKidFields', async ({ locale, token, ifEdit, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/kidField?id=${id}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContact = createAsyncThunk('transaction/startFetchContact', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/fetchContact?contact_id=${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchFiles = createAsyncThunk('transaction/startFetchFiles', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/fetchFiles?transaction_id=${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchTransaction = createAsyncThunk('transaction/startFetchTransaction', async ({ transaction, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${TRANSACTION}/edit/${transaction}`, { headers });

        const data = res.data;
        
        const results = {
            edit: true,
            id: data.id,
            inc_or_exp: data.inc_or_exp,
            operator_id: data.operator_id,
            contact_id: data.contact_id,
            price: data.price,
            payment_date: data.payment_date ? new Date(data.payment_date) : null,
            basic_id: data.basic_id,
            secondary_id: data.secondary_id,
            specific_id: data.specific_id,
            notes: data.notes,
            completed: data.completed,
            created_at: new Date(data.created_at),

            kid_id: data.kid_id,
            school_year: data.school_year ? data.school_year : "",
            s_date_study: data.s_date_study ? new Date(data.s_date_study) : null,
            e_date_study: data.e_date_study ? new Date(data.e_date_study) : null,
            extra_month: data.extra_month,
            transport: data.transport,
            s_route_type_id: data.s_route_type_id,
            e_route_type_id: data.e_route_type_id,
            s_date_trans: data.s_date_trans ? new Date(data.s_date_trans) : null,
            e_date_trans: data.e_date_trans ? new Date(data.e_date_trans) : null,
            departure_time: moment(new Date(data.departure_time)),
            trans_price: data.trans_price,
            trans_disc_price: data.trans_disc_price,
            trans_disc_percent: data.trans_disc_percent,

            files: [],
            filesData: data.filesData,
        };

        let newInvoices = [];

        data.invoices.forEach(element => {
            newInvoices.push({
                payment_type_id: element.payment_type_id,
                invoice_or_receipt: element.invoice_or_receipt,
                invoice_amount: element.invoice_amount,
                invoice_date: new Date(element.invoice_date),
                invoice_completed: element.invoice_completed,
                invoice_percent_vat: element.invoice_percent_vat,
                invoice_vat: element.invoice_vat,
                invoice_number: element.invoice_number
            });
        });

        results.invoices = newInvoices;

        dispatch(transactionSlice.actions.setBasicFields(data.basics));
        dispatch(transactionSlice.actions.setSecondaryFields(data.secondaries));
        dispatch(transactionSlice.actions.setSpecificFields(data.specifics));
        dispatch(transactionSlice.actions.setContactFields(data.contacts));
        dispatch(transactionSlice.actions.setKidFields(data.kids));

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('transaction/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('transaction/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('transaction/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    transactions: {
        data: [],
        totalItemCount: 0,
        income: 0.00,
        expenses: 0.00,
        picture: 0.00
    },
    searchFields: {
        inc_or_exp: [],
        no_or_yes: [],
        payments: [],
        basics: [],
        secondaries: [],
        specifics: []
    },
    searchFilter: {
        inc_or_exp: '',
        create_date_from: null,
        create_date_to: null,
        payment_date_from: null,
        payment_date_to: null,
        complete: '',
        payment: '',
        basic: '',
        secondary: '',
        specific: ''
    },
    selectedPageSize: 10,
    sortObj: null,
    search: "",
    searchValue: "",
    currentPage: 1,
    transactionData: {
        id: '',
        inc_or_exp: 0,
        created_at:  new Date(),
        operator_id:  '',
        contact_id:  '',
        price:  '',
        payment_date:  null,
        basic_id: '',
        secondary_id: '',
        specific_id: '',
        notes: '',
        completed: 0,
        files: [],
        filesData: [],
        invoices: [{
            payment_type_id: '',
            invoice_or_receipt: 0,
            invoice_amount: '',
            invoice_date: null,
            invoice_completed: 0,
            invoice_percent_vat: 0,
            invoice_vat: 0,
            invoice_number: ''
        }],

        kid_id:  '',
        school_year: '',
        s_date_study: null,
        e_date_study: null,
        extra_month: 0,
        transport: 0,
        s_route_type_id: '',
        e_route_type_id: '',
        s_date_trans: null,
        e_date_trans: null,
        departure_time: '',
        trans_price: 0,
        trans_disc_price: 0,
        trans_disc_percent: 0
    },
    createTransaction: '',
    updateTransaction: '',
    deleteTransaction: '',
    dataFields: {
        routes: [],
        files: [],
        school_years: [],
        users: [],
        payments: [],
        basics: [],
        secondaries: [],
        specifics: [],
        contacts: [],
        kids: []
    },
    files: [],
    contact: {},
    loadingModal: false,
    loadingBtn: false,
    loadingContactField: false,
    loading: false,
    loadingList: false,
    error: ''
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        fetchTransactionsSuccess(state, { payload }) {
            return { ...state, loading: false, transactions: payload, error: '' };
        },
        clearTransaction(state) {
            return { ...state, loadingBtn: false, loading: false, loadingList: false, loadingContactField: false, loadingModal: false, transactionData: initialState.transactionData, error: '' };
        },
        clearAlertTransaction(state) {
            return { ...state, updateTransaction: '', createTransaction: '', deleteTransaction: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setBasicFields(state, { payload }){
            const basicFields = {...state.dataFields};
            basicFields.basics = payload;

            return { ...state, dataFields: basicFields, error: '' };
        },
        setSecondaryFields(state, { payload }){
            const secondaryFields = {...state.dataFields};
            secondaryFields.secondaries = payload;

            return { ...state, dataFields: secondaryFields, error: '' };
        },
        setSpecificFields(state, { payload }){
            const specificFields = {...state.dataFields};
            specificFields.specifics = payload;

            return { ...state, dataFields: specificFields, error: '' };
        },
        setContactFields(state, { payload }){
            const contactFields = {...state.dataFields};
            contactFields.contacts = payload;

            return { ...state, dataFields: contactFields, error: '' };
        },
        setKidFields(state, { payload }){
            const kidFields = {...state.dataFields};
            kidFields.kids = payload;

            return { ...state, dataFields: kidFields, error: '' };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilter: payload }
        },
        clearFilter(state) {
            return { ...state, searchFilter: initialState.searchFilter }
        },
    },
    extraReducers: {
      [startFetchTransactions.pending]: (state) => {
        return { ...state, loadingList: true, error: '' };
      },
      [startFetchTransactions.fulfilled]: (state, { payload }) => {
        return { ...state, loadingList: false, transactions: payload, error: '' };
      },
      [startFetchTransactions.rejected]: (state, { payload }) => {
        return { ...state, loadingList: false, transactions: initialState.transactions, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, searchFields: payload, loading: false, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, searchFields: initialState.searchFields, error: payload };
      },

      [startFetchBasicFieldsList.fulfilled]: (state, { payload }) => {
        const basicFieldsList = {...state.searchFields};
        basicFieldsList.basics = payload;
        basicFieldsList.secondaries = [];
        basicFieldsList.specifics = [];
        
        return { ...state, searchFields: basicFieldsList };
      },
      [startFetchBasicFieldsList.rejected]: (state, { payload }) => {
        const basicFieldsListError = {...state.searchFields};
        basicFieldsListError.basics = [];
        basicFieldsListError.secondaries = [];
        basicFieldsListError.specifics = [];

        return { ...state, searchFields: basicFieldsListError };
      },

      [startFetchSecondaryFieldsList.fulfilled]: (state, { payload }) => {
        const secondaryFieldsList = {...state.searchFields};
        secondaryFieldsList.secondaries = payload;
        secondaryFieldsList.specifics = [];
        
        return { ...state, searchFields: secondaryFieldsList };
      },
      [startFetchSecondaryFieldsList.rejected]: (state) => {
        const secondaryFieldsListError = {...state.searchFields};
        secondaryFieldsListError.secondaries = [];
        secondaryFieldsListError.specifics = [];

        return { ...state, searchFields: secondaryFieldsListError };
      },

      [startFetchSpecificFieldsList.fulfilled]: (state, { payload }) => {
        const specificFieldsList = {...state.searchFields};
        specificFieldsList.specifics = payload;
        
        return { ...state, searchFields: specificFieldsList };
      },
      [startFetchSpecificFieldsList.rejected]: (state) => {
        const specificFieldsListError = {...state.searchFields};
        specificFieldsListError.specifics = [];

        return { ...state, searchFields: specificFieldsListError };
      },

      [startCreateTransaction.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateTransaction.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createTransaction: payload, error: '' };
      },
      [startCreateTransaction.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createTransaction: '', error: payload };
      },

      [startUpdateTransaction.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateTransaction.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateTransaction: payload, error: '' };
      },
      [startUpdateTransaction.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateTransaction: '', error: payload };
      },

      [startFetchTransaction.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchTransaction.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, transactionData: payload, error: '' };
      },
      [startFetchTransaction.rejected]: (state, { payload }) => {
        return { ...state, loading: false, transactionData: '', error: payload };
      },

      [startDeleteTransaction.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteTransaction: payload, error: '' };
      },
      [startDeleteTransaction.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteTransaction: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchBasicFields.fulfilled]: (state, { payload }) => {
        const basicFields = {...state.dataFields};
        basicFields.basics = payload;
        
        return { ...state, dataFields: basicFields, error: '' };
      },
      [startFetchBasicFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSecondaryFields.fulfilled]: (state, { payload }) => {
        const secondaryFields = {...state.dataFields};
        secondaryFields.secondaries = payload;
        
        return { ...state, dataFields: secondaryFields, error: '' };
      },
      [startFetchSecondaryFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSpecificFields.fulfilled]: (state, { payload }) => {
        const specificFields = {...state.dataFields};
        specificFields.specifics = payload;
        
        return { ...state, dataFields: specificFields, error: '' };
      },
      [startFetchSpecificFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchContactFields.pending]: (state) => {
        return { ...state, loadingContactField: true, error: '' };
      },
      [startFetchContactFields.fulfilled]: (state, { payload }) => {
        const contactFields = {...state.dataFields};
        contactFields.contacts = payload;
        
        return { ...state, dataFields: contactFields, loadingContactField: false, error: '' };
      },
      [startFetchContactFields.rejected]: (state, { payload }) => {
        return { ...state, loadingContactField: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchKidFields.fulfilled]: (state, { payload }) => {
        const kidFields = {...state.dataFields};
        kidFields.kids = payload;
        
        return { ...state, dataFields: kidFields, error: '' };
      },
      [startFetchKidFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchContact.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, contact: payload, error: '' };
      },
      [startFetchContact.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, contact: initialState.contact, error: payload };
      },

      [startFetchFiles.pending]: (state) => {
        return { ...state, loadingModal: true, error: '' };
      },
      [startFetchFiles.fulfilled]: (state, { payload }) => {
        return { ...state, loadingModal: false, files: payload, error: '' };
      },
      [startFetchFiles.rejected]: (state, { payload }) => {
        return { ...state, loadingModal: false, files: initialState.files, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sortObj: payload }
      }

    },
});


export const transactionActions = {
    ...transactionSlice.actions,
    startFetchTransactions,
    startFetchSearchFields,
    startFetchBasicFieldsList,
    startFetchSecondaryFieldsList,
    startFetchSpecificFieldsList,
    startCreateTransaction,
    startUpdateTransaction,
    startFetchTransaction,
    startDeleteTransaction,
    startFetchDataFields,
    startFetchBasicFields,
    startFetchSecondaryFields,
    startFetchSpecificFields,
    startFetchContactFields,
    startFetchKidFields,
    startFetchContact,
    startFetchFiles,
    setSearch,
    setCurrentPage,
    setSort
}
export default transactionSlice.reducer