/* eslint-disable camelcase */
import common_el from './el/common.json';
import common_en from './en/common.json';

const trans = {
  en: {
    common: common_en
  },
  el: {
    common: common_el
  }
}

export default trans;
