import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, KID } from '../../constants/defaultValues';

const startFetchKids = createAsyncThunk('kid/startFetchKids', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let dataField = "";
        let order = "";

        if (filter.sortObj) {
            dataField = filter.sortObj.dataField;
            order = filter.sortObj.order;
        }

        const res = await axios.get(`${KID}?pageSize=${filter.pageSize}&dataField=${dataField}&order=${order}&currentPage=${filter.currentPage}&search=${filter.search}
        &school_year=${filter.filter ? filter.filter.school_years : ""}
        &educators=${filter.filter ? filter.filter.educators : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('kid/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KID}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateKid = createAsyncThunk('kid/startCreateKid', async ({ kid, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${KID}/store`, kid, { headers });

        if(res.data){
            form.restart()

            dispatch(kidSlice.actions.setPage(1));
            dispatch(kidSlice.actions.setTabs([false, true, true, true]));
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateKid = createAsyncThunk('kid/startUpdateKid', async ({ id, kid, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${KID}/update/${id}`, kid, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteKid = createAsyncThunk('kid/startDeleteKid', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${KID}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveKid = createAsyncThunk('kid/startActiveKid', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${KID}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const startFetchDataFields = createAsyncThunk('kid/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KID}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchKid = createAsyncThunk('kid/startFetchKid', async ({ kid, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${KID}/edit/${kid}`, { headers });

        const data = res.data;

        let dates = [];

        data.dates.forEach(date => {
            dates.push({from_date: new Date(date.from_date), to_date: new Date(date.to_date), school_year: date.school_year})
        })
        
        const results = {
            edit: true,
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            city: data.city,
            birthday: data.birthday ? new Date(data.birthday) : null,
            age: data.age,
            adjustment_date: data.adjustment_date ? new Date(data.adjustment_date) : null,
            dates: dates,
            address: data.address,
            lat: data.lat,
            lng: data.lng,
            s_address: data.s_address,
            s_lat: data.s_lat,
            s_lng: data.s_lng,
            e_address: data.e_address,
            e_lat: data.e_lat,
            e_lng: data.e_lng,
            notes: data.notes,
            educators: data.educators,
            contacts: data.contacts,
            files: [],
            filesData: data.filesData
        };

        dispatch(kidSlice.actions.setPage(1));
        dispatch(kidSlice.actions.setTabs([false, true, true, true]));

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('kid/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('kid/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('kid/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    kids: {
        data: [],
        totalItemCount: 0
    },
    searchFields: {
        school_years: [],
        educators: []
    },
    searchFilter: {
        school_years: '',
        educators: []
    },
    selectedPageSize: 10,
    sortObj: null,
    search: "",
    searchValue: "",
    currentPage: 1,
    tabs: [false, true, true, true],
    page: 1,
    kidData: {
        id: '',
        first_name: '',
        last_name: '',
        birthday: null,
        age: '',
        adjustment_date: null,
        dates: [{from_date: null, to_date: null, school_year: ""}],
        city: '',
        address: '',
        lat: '',
        lng: '',
        s_address: '',
        s_lat: '',
        s_lng: '',
        e_address: '',
        e_lat: '',
        e_lng: '',
        notes: '',
        educators: [],
        contacts: [],
        files: [],
        filesData: []
    },
    createKid: '',
    updateKid: '',
    deleteKid: '',
    activeKid: '',
    dataFields: {
        school_years: [],
        files: [],
        relations: [],
        countries: [],
        roles: [],
        educators: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const kidSlice = createSlice({
    name: 'kid',
    initialState,
    reducers: {
        fetchKidsSuccess(state, { payload }) {
            return { ...state, loading: false, kids: payload, error: '' };
        },
        clearKid(state) {
            return { ...state, page: 1, tabs: [false, true, true, true], loadingBtn: false, loading: false, kidData: initialState.kidData, error: '' };
        },
        clearAlertKid(state) {
            return { ...state, updateKid: '', createKid: '', deleteKid: '', activeKid: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setTabs(state, { payload }) {
            return { ...state, tabs: payload }
        },
        setPage(state, { payload }) {
            return { ...state, page: payload }
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilter: payload }
        },
        clearFilter(state) {
            return { ...state, searchFilter: initialState.searchFilter }
        },
    },
    extraReducers: {
      [startFetchKids.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchKids.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, kids: payload, error: '' };
      },
      [startFetchKids.rejected]: (state, { payload }) => {
        return { ...state, loading: false, kids: initialState.kids, error: payload };
      },

      [startCreateKid.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateKid.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createKid: payload, error: '' };
      },
      [startCreateKid.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createKid: '', error: payload };
      },

      [startUpdateKid.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateKid.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateKid: payload, error: '' };
      },
      [startUpdateKid.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateKid: '', error: payload };
      },

      [startFetchKid.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchKid.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, kidData: payload, error: '' };
      },
      [startFetchKid.rejected]: (state, { payload }) => {
        return { ...state, loading: false, kidData: '', error: payload };
      },

      [startDeleteKid.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteKid: payload, error: '' };
      },
      [startDeleteKid.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteKid: '', error: payload };
      },

      [startActiveKid.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeKid: payload, error: '' };
      },
      [startActiveKid.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeKid: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, searchFields: payload, loading: false, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, searchFields: initialState.searchFields, error: payload };
      },


      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sortObj: payload }
      }

    },
});


export const kidActions = {
    ...kidSlice.actions,
    startFetchKids,
    startCreateKid,
    startUpdateKid,
    startFetchKid,
    startDeleteKid,
    startActiveKid,
    startFetchDataFields,
    startFetchSearchFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default kidSlice.reducer