import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, USER } from '../../constants/defaultValues';

const startFetchUsers = createAsyncThunk('user/startFetchUsers', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let dataField = "";
        let order = "";

        if (filter.sortObj) {
            dataField = filter.sortObj.dataField;
            order = filter.sortObj.order;
        }

        const res = await axios.get(`${USER}?pageSize=${filter.pageSize}&dataField=${dataField}&order=${order}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateUser = createAsyncThunk('user/startCreateUser', async ({ user, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/store`, user, { headers });

        if(res.data){
            form.restart()
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateUser = createAsyncThunk('user/startUpdateUser', async ({ id, user, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${USER}/update/${id}`, user, { headers });

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteUser = createAsyncThunk('user/startDeleteUser', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${USER}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveUser = createAsyncThunk('user/startActiveUser', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${USER}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startChangePassword = createAsyncThunk('user/startChangePassword', async ({ id, data, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${USER}/changePassword/${id}`, data, { headers });

        form.restart();
        
        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startWelcomeUser = createAsyncThunk('user/startWelcomeUser', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/welcome/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            console.log(err)
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('user/startFetchDataFields', async ({ locale, token, ifEdit, user_id = "" }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/dataFields?lang=${locale}&edit=${ifEdit}&user_id=${user_id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContactFields = createAsyncThunk('user/startFetchContactFields', async ({ locale, token, ifEdit, keywords }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/selectContacts?keywords=${keywords}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchUser = createAsyncThunk('user/startFetchUser', async ({ user, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${USER}/edit/${user}`, { headers });

        const data = res.data;
        
        const results = {
          edit: true,
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_iso: data.phone_iso,
          phone_code: data.phone.split(' ')[0],
          mobile_iso: data.mobile_iso,
          phone: data.phone.split(' ')[1],
          mobile: data.mobile.split(' ')[1],
          mobile_code: data.mobile.split(' ')[0],
          c_phone_iso:data.c_phone ? data.c_phone_iso : "",
          c_phone_code: data.c_phone ? data.c_phone.split(' ')[0] : "",
          c_phone: data.c_phone ? data.c_phone.split(' ')[1] : "",
          image: data.image ? data.image : '',
          file: '',
          contact_id: data.contact_id,
          role_id: data.role_id[0].id
        };

        dispatch(userSlice.actions.setContactFields(data.contacts));

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('user/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('user/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('user/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    users: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    sortObj: null,
    search: "",
    searchValue: "",
    currentPage: 1,
    userData: {
        first_name: '',
        last_name: '',
        phone: '',
        phone_code: '',
        phone_iso: '',
        mobile: '',
        mobile_code: '',
        mobile_iso: '',
        c_phone: '',
        c_phone_code: '',
        c_phone_iso: '',
        email: '',
        role_id: '',
        contact_id: '',
        image: '',
        file: ''
    },
    createUser: '',
    updateUser: '',
    changePasswordUser: '',
    deleteUser: '',
    activeUser: '',
    welcomeUser: '',
    dataFields: {
        roles: [],
        countries: [],
        contacts: []
    },
    loadingPasswordBtn: false,
    loadingContactField: false,
    loadingBtn: false,
    loading: true,
    error: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        fetchUsersSuccess(state, { payload }) {
            return { ...state, loading: false, users: payload, error: '' };
        },
        clearUser(state) {
            return { ...state, loadingPasswordBtn: false, loadingBtn: false, loading: false, loadingContactField: false, userData: initialState.userData, error: '' };
        },
        clearAlertUser(state) {
            return { ...state, updateUser: '', createUser: '', deleteUser: '', activeUser: '', welcomeUser: '', changePasswordUser: '', changeEmailPasswordUser: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setContactFields(state, { payload }){
            const contactFields = {...state.dataFields};
            contactFields.contacts = payload;

            return { ...state, dataFields: contactFields, error: '' };
        },
    },
    extraReducers: {
      [startFetchUsers.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchUsers.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, users: payload, error: '' };
      },
      [startFetchUsers.rejected]: (state, { payload }) => {
        return { ...state, loading: false, users: initialState.users, error: payload };
      },

      [startCreateUser.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateUser.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createUser: payload, error: '' };
      },
      [startCreateUser.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createUser: '', error: payload };
      },

      [startUpdateUser.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateUser.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateUser: payload, error: '' };
      },
      [startUpdateUser.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateUser: '', error: payload };
      },

      [startFetchUser.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, userData: payload, error: '' };
      },
      [startFetchUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, userData: '', error: payload };
      },

      [startDeleteUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteUser: payload, error: '' };
      },
      [startDeleteUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteUser: '', error: payload };
      },

      [startActiveUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeUser: payload, error: '' };
      },
      [startActiveUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeUser: '', error: payload };
      },

      [startChangePassword.pending]: (state) => {
        return { ...state, loadingPasswordBtn: true, error: '' };
      },
      [startChangePassword.fulfilled]: (state, { payload }) => {
        return { ...state, loadingPasswordBtn: false, changePasswordUser: payload, error: '' };
      },
      [startChangePassword.rejected]: (state, { payload }) => {
        return { ...state, loadingPasswordBtn: false, changePasswordUser: '', error: payload };
      },

      [startWelcomeUser.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, welcomeUser: payload, error: '' };
      },
      [startWelcomeUser.rejected]: (state, { payload }) => {
        return { ...state, loading: false, welcomeUser: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchContactFields.pending]: (state) => {
        return { ...state, loadingContactField: true, error: '' };
      },
      [startFetchContactFields.fulfilled]: (state, { payload }) => {
        const contactFields = {...state.dataFields};
        contactFields.contacts = payload;
        
        return { ...state, dataFields: contactFields, loadingContactField: false, error: '' };
      },
      [startFetchContactFields.rejected]: (state, { payload }) => {
        return { ...state, loadingContactField: false, dataFields: initialState.dataFields, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sortObj: payload }
      }

    },
});


export const userActions = {
    ...userSlice.actions,
    startFetchUsers,
    startCreateUser,
    startUpdateUser,
    startFetchUser,
    startDeleteUser,
    startActiveUser,
    startChangePassword,
    startWelcomeUser,
    startFetchDataFields,
    startFetchContactFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default userSlice.reducer