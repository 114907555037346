import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, CATEGORIES } from '../../constants/defaultValues';
import moment from 'moment';

const startFetchDataFields = createAsyncThunk('category/startFetchDataFields', async ({ locale, token, ifEdit, category }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CATEGORIES}/dataFields/${category}?lang=${locale}&edit=${ifEdit ? 1 : 0}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectFields = createAsyncThunk('category/startFetchDataSelectFields', async ({ locale, token, ifEdit, category, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CATEGORIES}/selectField/${category}?id=${id}&edit=${ifEdit ? 1 : 0}`, { headers });

        return { category: category, data: res.data};
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataSelectSecondFields = createAsyncThunk('category/startFetchDataSelectSecondFields', async ({ locale, token, ifEdit, category, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CATEGORIES}/selectSecondField/${category}?id=${id}&edit=${ifEdit ? 1 : 0}`, { headers });

        return { category: category, data: res.data};
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCategories = createAsyncThunk('category/startFetchCategories', async ({ locale, token, filter, category }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let dataField = "";
        let order = "";

        if (filter.sortObj) {
            dataField = filter.sortObj.dataField;
            order = filter.sortObj.order;
        }

        const res = await axios.get(`${CATEGORIES}/${category}?pageSize=${filter.pageSize}&dataField=${dataField}&order=${order}&currentPage=${filter.currentPage}&search=${filter.search}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateCategory = createAsyncThunk('category/startCreateCategory', async ({ categoryData, locale, token, form, category }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CATEGORIES}/store/${category}`, categoryData, { headers });

        if(res.data){
            form.restart()
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateCategory = createAsyncThunk('category/startUpdateCategory', async ({ id, categoryData, locale, token, category }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CATEGORIES}/update/${category}/${id}`, categoryData, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteCategory = createAsyncThunk('category/startDeleteCategory', async ({ locale, token, id, category }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CATEGORIES}/destroy/${category}/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveCategory = createAsyncThunk('category/startActiveCategory', async ({ locale, token, id, action, category }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CATEGORIES}/active/${category}/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchCategory = createAsyncThunk('category/startFetchCategory', async ({ categoryData, history, locale, token, category }, { rejectWithValue, dispatch}) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CATEGORIES}/edit/${category}/${categoryData}`, { headers });

        const data = res.data;

        const results = {
            edit: true,
            category: data.category,
            name_en: data.name_en,
            name_el: data.name_el,
        };

        if(category === "basic" || category === "secondary" || category === "specific"){
            results.inc_or_exp = data.inc_or_exp;

            if(data.inc_or_exp === 0 && category === "basic"){
                results.months = data.months;
                results.price = data.price;
                results.disc = data.disc;
                results.disc_price = data.disc_price;
                results.price_per_month = data.price_per_month;
                results.trans_price = data.trans_price;
                results.final_price = data.final_price;
            }
        }

        if(category === "secondary" || category === "specific"){
            results.basic_type_id = data.basic_type_id;
            dispatch(categorySlice.actions.dataSelectFields(data.basic_categories))
        }

        if(category === "specific"){
            results.secondary_type_id = data.secondary_type_id;
            dispatch(categorySlice.actions.dataSelectSecondFields(data.secondary_categories))
        }

        if(category === "route"){
            results.user_id = data.user_id;
            results.color = data.color;
            results.seats = data.seats;
            results.start_point = data.start_point;
            results.start_lat = data.start_lat;
            results.start_lng = data.start_lng;
            results.end_point = data.end_point;
            results.end_lat = data.end_lat;
            results.end_lng = data.end_lng;
            results.start_time = data.start_time ? moment(new Date(data.start_time)) : "";
            results.end_time = data.end_time ? moment(new Date(data.end_time)) : "";
        }

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

//Heading Functions
const setSearch = createAsyncThunk('category/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('category/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('category/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    categories: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 5,
    search: "",
    searchValue: "",
    currentPage: 1,
    sortObj: null,
    createCategory: '',
    updateCategory: '',
    deleteCategory: '',
    activeCategory: '',
    categoryData: {
        edit: false,
        category: '',
        name_en: '',
        name_el: '',

        inc_or_exp: '',
        basic_type_id: '',
        secondary_type_id: '',
        
        months: '',
        disc: '',
        price_per_month: '',
        trans_price: '',
        final_price: 0,
        price: 0,
        disc_price: 0,
        user_id: '',
        color: '',
        seats: '',
        start_point: '',
        start_lat: '',
        start_lng: '',
        end_point: '',
        end_lat: '',
        end_lng: '',
        start_time: '',
        end_time: ''
    },
    dataFields: {
        basic_categories: [],
        secondary_categories: [],
        drivers: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        fetchCategoriesSuccess(state, { payload }) {
            return { ...state, loading: false, categories: payload, error: '' };
        },
        clearCategory(state) {
            return { ...state, loadingBtn: false, loading: false, categoryData: initialState.categoryData, error: '' };
        },
        clearAlertCategory(state) {
            return { ...state, updateCategory: '', createCategory: '', deleteCategory: '', activeCategory: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        dataSelectFields(state, { payload }){
            const selectField = {...state.dataFields};
            selectField.basic_categories = payload;

            return { ...state, dataFields: selectField, error: '' };
        },
        dataSelectSecondFields(state, { payload }){
            const selectSecondField = {...state.dataFields};
            selectSecondField.secondary_categories = payload;
            
            return { ...state, dataFields: selectSecondField, error: '' };
        }
    },
    extraReducers: {
      [startFetchCategories.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCategories.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, categories: payload, error: '' };
      },
      [startFetchCategories.rejected]: (state, { payload }) => {
        return { ...state, loading: false, categories: initialState.categories, error: payload };
      },

      [startFetchCategory.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, categoryData: payload, error: '' };
      },
      [startFetchCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, categoryData: '', error: payload };
      },
      
      [startCreateCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCategory: payload, error: '' };
      },
      [startCreateCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createCategory: '', error: payload };
      },

      [startUpdateCategory.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCategory: payload, error: '' };
      },
      [startUpdateCategory.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateCategory: '', error: payload };
      },

      [startDeleteCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteCategory: payload, error: '' };
      },
      [startDeleteCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteCategory: '', error: payload };
      },

      [startActiveCategory.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeCategory: payload, error: '' };
      },
      [startActiveCategory.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeCategory: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload }; 
      },

      [startFetchDataSelectFields.fulfilled]: (state, { payload }) => {
        const {category, data } = payload;
        const selectField = {...state.dataFields};
        
        if(category === "secondary" || category === "specific"){
            selectField.basic_categories = data ? data : [];
        }

        return { ...state, dataFields: selectField, error: '' };
      },
      [startFetchDataSelectFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchDataSelectSecondFields.fulfilled]: (state, { payload }) => {
        const {category, data } = payload;
        const selectSecondField = {...state.dataFields};
        
        if(category === "specific"){
            selectSecondField.secondary_categories = data ? data : [];
        }
        
        return { ...state, dataFields: selectSecondField, error: '' };
      },
      [startFetchDataSelectSecondFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },  
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sortObj: payload }
      }
    },
});


export const categoryActions = {
    ...categorySlice.actions,
    startFetchCategories,
    startFetchCategory,
    startCreateCategory,
    startUpdateCategory,
    startDeleteCategory,
    startActiveCategory,
    startFetchDataFields,
    startFetchDataSelectFields,
    startFetchDataSelectSecondFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default categorySlice.reducer