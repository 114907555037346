import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, ROUTE_MAP } from '../../constants/defaultValues';


const startFetchRouteMap = createAsyncThunk('routeMap/startFetchRouteMap', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ROUTE_MAP}?&school_year=${filter.filter.school_year}&route_type=${filter.filter.route_type}`, { headers });

        return res.data;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('routeMap/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ROUTE_MAP}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRouteKids = createAsyncThunk('routeMap/startFetchRouteKids', async ({ locale, token, id, school_year, route_type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ROUTE_MAP}/getRouteKids?id=${id}&school_year=${school_year}&route_type=${route_type}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startSaveRouteKids = createAsyncThunk('routeMap/startSaveRouteKids', async ({ locale, token, id, kids, openModal, school_year, route_type }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ROUTE_MAP}/saveRouteKids`, {id: id, kids: kids, school_year: school_year, route_type: route_type }, { headers });

        openModal();

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    routeMapData: [],
    kids: [],
    saveKids: '',
    searchFields: {
        school_years: [],
        route_types: [],
    },
    searchFilter: {
        school_year: 1,
        route_type: 1
    },
    loading: true,
    loadingModal: false,
    loadingBtn: false,
    error: ''
}

export const routeMapSlice = createSlice({
    name: 'routeMap',
    initialState,
    reducers: {
        fetchRouteMapSuccess(state, { payload }) {
            return { ...state, loading: false, routeMapData: payload, error: '' };
        },
        fetchRouteKidsSuccess(state, { payload }) {
            return { ...state, loading: false, kids: payload, error: '' };
        },
        clearAlertRouteMap(state) {
            return { ...state, saveKids: '', error: '' };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilter: payload }
        },
        clearFilter(state) {
            return { ...state, searchFilter: initialState.searchFilter }
        },
    },
    extraReducers: {
        [startFetchSearchFields.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchSearchFields.fulfilled]: (state, { payload }) => {
            return { ...state, searchFields: payload, loading: false, error: '' };
        },
        [startFetchSearchFields.rejected]: (state, { payload }) => {
            return { ...state, loading: false, searchFields: initialState.searchFields, error: payload };
        },

        [startFetchRouteMap.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchRouteMap.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, routeMapData: payload, error: '' };
        },
        [startFetchRouteMap.rejected]: (state, { payload }) => {
            return { ...state, loading: false, routeMapData: initialState.routeMapData, error: payload };
        },

        [startFetchRouteKids.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startFetchRouteKids.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, kids: payload, error: '' };
        },
        [startFetchRouteKids.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, kids: initialState.kids, error: payload };
        },

        [startSaveRouteKids.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startSaveRouteKids.fulfilled]: (state, { payload }) => {
            let newRouteMap = [...state.routeMapData];

            newRouteMap.forEach((item, i) => {
                if(item.id === payload.id){  
                    newRouteMap[i] = payload;
                }
            });

            return { ...state, loadingBtn: false, saveKids: 'success', routeMapData: newRouteMap, error: '' };
        },
        [startSaveRouteKids.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, saveKids: '', error: payload };
        }
    },
});


export const routeMapActions = {
    ...routeMapSlice.actions,
    startFetchSearchFields,
    startFetchRouteMap,
    startFetchRouteKids,
    startSaveRouteKids
}
export default routeMapSlice.reducer