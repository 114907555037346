import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Router';
import ScrollToTop from './containers/App/ScrollToTop';
import GlobalStyles from './containers/App/globalStyles';
import TimepickerStyles from './components/date-pickers/timepickerStyles';
import NotificationStyles from './containers/App/notificationStyles';
import { FullWideNotification } from './components/notification/Notification';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { config as i18nextConfig } from './translations';
import { LastLocationProvider } from 'react-router-last-location';

i18n.init(i18nextConfig);

const App = () => {
  const { mode, direction, shadow, border } = useSelector((state) => state.options);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return (
      <BrowserRouter>
        <LastLocationProvider>
          <I18nextProvider i18n={i18n}>
            <ScrollToTop>
              <div>
                {!isOnline ? 
                  <FullWideNotification color={"danger"} message={"No Internet Connection..."} />
                : null}
                  <ThemeProvider
                    theme={{
                      mode: mode,
                      direction: direction,
                      shadow: shadow,
                      border: border
                    }}
                  >
                    <GlobalStyles />
                    <NotificationStyles />
                    <TimepickerStyles />
                    <Router />
                  </ThemeProvider>
              </div>
            </ScrollToTop>
          </I18nextProvider>
        </LastLocationProvider>
      </BrowserRouter>
  );
};

export default App;
