import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, START } from '../../constants/defaultValues';

const startFetchHome = createAsyncThunk('start/startFetchHome', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${START}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const initialState = {
    home: {
        kids: 0,
        contacts: 0,
        incomes: 0,
        expenses: 0,
        transactionsChart: []
    },
    loading: false,
    error: ''
}

export const startSlice = createSlice({
    name: 'start',
    initialState,
    reducers: {
        clearAlertStart(state) {
            state.error = ''
        },
        clearStart(state) {
            state = {...initialState}
        }
    },
    extraReducers: {
      [startFetchHome.pending]: (state) => {
        state.loading = true
        state.error = ''
      },
      [startFetchHome.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.error = '';
        state.home = payload;
      },
      [startFetchHome.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.home = initialState.home
      }
    },
});

export const startActions = {
    ...startSlice.actions,
    startFetchHome
}
export default startSlice.reducer