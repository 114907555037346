import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';

import authUserReducer from './auth/authUserSlice';
import optionsReducer from './options/optionsSlice';
import startReducer from './start/startSlice';
import permissionReducer from './permissions/permissionSlice';
import roleReducer from './roles/roleSlice';
import userReducer from './users/userSlice';
import categoryReducer from './categories/categorySlice';
import kidReducer from './kids/kidSlice';
import contactReducer from './contacts/contactSlice';
import transactionReducer from './transactions/transactionSlice';
import routeMapReducer from './route-map/routeMapSlice';
import closedDayReducer from './closed-days/closedDaySlice';
import dayOffReducer from './days-off/dayOffSlice';
import absenceReducer from './absences/absenceSlice';
import calendarReducer from './calendar/calendarSlice';

const persistConfig = {
  key: 'root',
  storage,
  transform: [
    createFilter('authUser', ['author'])
  ]
}

const rootReducer = combineReducers({
  authUser: authUserReducer,
  options: optionsReducer,
  start: startReducer,
  permission: permissionReducer,
  role: roleReducer,
  user: userReducer,
  category: categoryReducer,
  kid: kidReducer,
  contact: contactReducer,
  transaction: transactionReducer,
  routeMap: routeMapReducer,
  closedDay: closedDayReducer,
  dayOff: dayOffReducer,
  absence: absenceReducer,
  calendar: calendarReducer
});

export default persistReducer(persistConfig, rootReducer);