import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    direction: 'ltr',
    mode: 'dark',
    border: 'on',
    shadow: 'on',
    show: false,
    collapse: false,
    withBoxShadow: false,
    topNavigation: false
}

export const optionsSlice = createSlice({
    name: 'options',
    initialState,
    reducers: {
        changeThemeToDark(state) {
            return { ...state, mode: 'dark' };
        },
        changeThemeToLight(state) {
            return { ...state, mode: 'light' };
        },
        changeSidebarVisibility(state) {
            return { ...state, collapse: !state.collapse };
        },
        changeMobileSidebarVisibility(state) {
            return { ...state, show: !state.show };
        },
        changeDirectionToLTR(state) {
            return { ...state, direction: 'ltr' };
        },
        changeDirectionToRTL(state) {
            return { ...state, direction: 'rtl' };
        },
        changeRoundBordersToOnAction(state) {
            return {...state, border: 'on'};
        },
        changeRoundBordersToOffAction(state) {
            return {...state, border: 'off'};
        },
        changeBlocksShadowsToOnAction(state) {
            return {...state, shadow: 'on'};
        },
        changeBlocksShadowsToOffAction(state) {
            return {...state, shadow: 'off'};
        },
        toggleBoxShadow(state) {
            return { ...state, withBoxShadow: !state.withBoxShadow };
        },
        toggleTopNavigation(state) {
            return { ...state, topNavigation: !state.topNavigation };
        },
    },
    extraReducers: {}
});

export const { changeThemeToDark, changeThemeToLight, changeSidebarVisibility, changeMobileSidebarVisibility, 
    changeDirectionToLTR, changeDirectionToRTL, changeRoundBordersToOnAction, changeRoundBordersToOffAction, 
    changeBlocksShadowsToOnAction, changeBlocksShadowsToOffAction, toggleBoxShadow, toggleTopNavigation} = optionsSlice.actions

export default optionsSlice.reducer