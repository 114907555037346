import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, ABSENCE } from '../../constants/defaultValues';
import moment from "moment";

const startFetchAbsences = createAsyncThunk('absence/startFetchAbsences', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        console.log(filter)
        dispatch(absenceSlice.actions.actionAbsence(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let dataField = "";
        let order = "";

        if (filter.sortObj) {
            dataField = filter.sortObj.dataField;
            order = filter.sortObj.order;
        }

        const res = await axios.get(`${ABSENCE}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &dataField=${dataField}
        &order=${order}
        &search=${filter.search}
        &users=${filter.filter.user.length > 0 ? filter.filter.user.join() : ""}
        &types=${filter.filter.type.length > 0 ? filter.filter.type.join() : ""}
        &status=${filter.filter.status.length > 0 ? filter.filter.status.join() : ""}
        &durations=${filter.filter.duration.length > 0 ? filter.filter.duration.join() : ""}
    
        &fromDate_type=${filter.filter.fromDate_type}
        &toDate_type=${filter.filter.toDate_type}
       
        `, { headers });

        return res.data;
    } catch (error) {
        console.log(error)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('absence/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ABSENCE}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('absence/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ABSENCE}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteAbsence = createAsyncThunk('absence/startDeleteAbsence', async ({ locale, token, id, absences }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${ABSENCE}/destroy/${id}`, { headers });

        const remove = absences.data.filter(item => item.id !== id);

        const newArray = {
            data: remove,
            totalItemCount: absences.totalItemCount - 1,
            daysOff: res.data.daysOff,
            daysSick: res.data.daysSick,
            daysUsers: absences.daysUsers
        }

        dispatch(absenceSlice.actions.fetchAbsencesSuccess(newArray))

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startStatusAbsence = createAsyncThunk('absence/startStatusAbsence', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${ABSENCE}/status/${id}`, { action: action }, { headers });
        
        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateAbsence = createAsyncThunk('absence/startUpdateAbsence', async ({ id, absence, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ABSENCE}/update/${id}`, absence, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAbsence = createAsyncThunk('absence/startFetchAbsence', async ({ absence, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${ABSENCE}/edit/${absence}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          id: data.id,
          user_id: data.user_id,
          type: data.type,
          status: data.status,
          duration: data.duration,
          time_from: data.time_from ? new Date(`${moment(new Date()).format('YYYY/MM/DD')} ${data.time_from}:00`) : null,
          time_to: data.time_to ? new Date(`${moment(new Date()).format('YYYY/MM/DD')} ${data.time_to}:00`) : null,
          message: data.message,
          date_from: data.date_from ? new Date(data.date_from) : null,
          date_to: data.date_to ? new Date(data.date_to) : null,
          files: data.files
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const startCreateAbsence = createAsyncThunk('absence/startCreateAbsence', async ({ absence, locale, token, form, user_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${ABSENCE}/store`, absence, { headers });

        if(res.data){
            form.restart()
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});



//Heading Functions
const setSearch = createAsyncThunk('absence/setSearch', ( args) => {
    return args;
});

const setCurrentPage = createAsyncThunk('absence/setCurrentPage', ( args) => {
    return args;
});

const setSort = createAsyncThunk('absence/setSort', ( args) => {
    return args;
});

const initialState = {
    absences: {
        data: [],
        totalItemCount: 0,
        daysOff: 0,
        daysSick: 0,
        daysUsers: []
    },
    selectedPageSize: 5,
    sortObj: null,
    search: "",
    searchValue: "",
    searchFields: {
        users: [],
        types: [],
        status: [],
        durations: []
    },
    searchFilter: {
        user: [],
        type: [],
        status: [],
        duration: [],

        fromDate_type: "",
        toDate_type: ""
    },
    currentPage: 1,
    absenceData: {
        user_id: '',
        type: '',
        status: '',
        duration: 1,
        time_from: '',
        time_to: '',
        message: '',
        date_from: null,
        date_to: null,
        files: []
    },
    createAbsence: '',
    updateAbsence: '',
    deleteAbsence: '',
    statusAbsence: '',
    dataFields: {
        users: [],
        types: [],
        durations: []
    },
    loadingBtn: false,
    loading: true,
    loadingSearch: false,
    error: ''
}

export const absenceSlice = createSlice({
    name: 'absence',
    initialState,
    reducers: {
        actionAbsence(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        },
        fetchAbsencesSuccess(state, { payload }) {
            return { ...state, absences: payload, loading: false, loadingSearch: false, error: '' };
        },
        clearAbsenceList(state) {
            return { ...state, loadingBtn: false, loading: true, loadingSearch: false, absenceData: initialState.absences, error: '' };
        },
        clearAbsence(state) {
            return { ...state, loadingBtn: false, loading: false, loadingSearch: false, absenceData: initialState.absenceData, error: '' };
        },
        clearAlertAbsence(state) {
            return { ...state, updateAbsence: '', createAbsence: '', deleteAbsence: '', statusAbsence: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 }
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilter: payload }
        },
        clearFilter(state) {
            return { ...state, searchFilter: initialState.searchFilter }
        },
    },
    extraReducers: {
      [startFetchAbsences.fulfilled]: (state, { payload }) => {
        return { ...state, absences: payload, loading: false, loadingSearch: false, error: '' };
      },
      [startFetchAbsences.rejected]: (state, { payload }) => {
        return { ...state, loading: false, loadingSearch: false, absences: initialState.absences, error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, searchFields: payload, loading: false, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, searchFields: initialState.searchFields, error: payload };
      },

      [startDeleteAbsence.fulfilled]: (state, { payload }) => {
        return { ...state, deleteAbsence: payload, error: '' };
      },
      [startDeleteAbsence.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteAbsence: '', error: payload };
      },

      [startStatusAbsence.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, statusAbsence: payload, error: '' };
      },
      [startStatusAbsence.rejected]: (state, { payload }) => {
        return { ...state, loading: false, statusAbsence: '', error: payload }; 
      },

      [startUpdateAbsence.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateAbsence.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateAbsence: payload, error: '' };
      },
      [startUpdateAbsence.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateAbsence: '', error: payload };
      },

      [startFetchAbsence.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchAbsence.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, absenceData: payload, error: '' };
      },
      [startFetchAbsence.rejected]: (state, { payload }) => {
        return { ...state, loading: false, absenceData: '', error: payload };
      },

      [startCreateAbsence.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateAbsence.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createAbsence: payload, error: '' };
      },
      [startCreateAbsence.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createAbsence: '', error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sortObj: payload }
      }
    },
});


export const absenceActions = {
    ...absenceSlice.actions,
    startFetchAbsences,
    startFetchDataFields,
    startFetchSearchFields,
    startDeleteAbsence,
    startStatusAbsence,
    startUpdateAbsence,
    startFetchAbsence,
    startCreateAbsence,
    setSearch,
    setCurrentPage,
    setSort
}
export default absenceSlice.reducer