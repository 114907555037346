import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../utils/Utils';
import axios from 'axios';
import { HEADERS, CONTACT } from '../../constants/defaultValues';

const startFetchContacts = createAsyncThunk('contact/startFetchContacts', async ({ locale, token, filter }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        let dataField = "";
        let order = "";

        if (filter.sortObj) {
            dataField = filter.sortObj.dataField;
            order = filter.sortObj.order;
        }

        const res = await axios.get(`${CONTACT}?pageSize=${filter.pageSize}&dataField=${dataField}&order=${order}&currentPage=${filter.currentPage}&search=${filter.search}&contact_type=${filter.filter ? filter.filter.contact_type : ""}&kid=${filter.kid ? filter.kid : ""}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('contact/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCreateContact = createAsyncThunk('contact/startCreateContact', async ({ contact, locale, token, form }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/store`, contact, { headers });

        if(res.data){
            form.restart()
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateContact = createAsyncThunk('contact/startUpdateContact', async ({ id, contact, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${CONTACT}/update/${id}`, contact, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteContact = createAsyncThunk('contact/startDeleteContact', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${CONTACT}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveContact = createAsyncThunk('contact/startActiveContact', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${CONTACT}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


const startFetchDataFields = createAsyncThunk('contact/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchContact = createAsyncThunk('contact/startFetchContact', async ({ contact, history, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${CONTACT}/edit/${contact}`, { headers });

        const data = res.data;
        
        const results = {
            edit: true,
            id: data.id,
            is_connected: data.is_connected,
            contact_type_id: data.contact_type_id,
            first_name: data.first_name,
            last_name: data.last_name,
            relation_type_id: data.relation_type_id,
            company: data.company,
            city: data.city,
            address: data.address,
            lat: data.lat,
            lng: data.lng,
            notes: data.notes,
            emails: data.emails,
            phones: data.phones,
            accounting: data.accounting
        };

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSearch = createAsyncThunk('contact/setSearch', ( args, { rejectWithValue }) => {
    return args;
});

const setCurrentPage = createAsyncThunk('contact/setCurrentPage', ( args, { rejectWithValue }) => {
    return args;
});

const setSort = createAsyncThunk('contact/setSort', ( args, { rejectWithValue }) => {
    return args;
});

const initialState = {
    contacts: {
        data: [],
        totalItemCount: 0
    },
    searchFields: {
        contact_types: []
    },
    searchFilter: {
        contact_type: ''
    },
    selectedPageSize: 10,
    sortObj: null,
    search: "",
    searchValue: "",
    currentPage: 1,
    contactData: {
        id: '',
        is_connected: 0,
        contact_type_id: '',
        first_name: '',
        last_name: '',
        relation_type_id: '',
        company: '',
        city: '',
        address: '',
        lat: '',
        lng: '',
        notes: '',
        emails: [''],
        phones: [{code: '0030', phone: '', type: 0}],
        accounting: {
            vat: '',
            tax_office: '',
            bank_account: '',
            iban: '',
            bank_name: '',
            holder_name: ''
        }
    },
    createContact: '',
    updateContact: '',
    deleteContact: '',
    activeContact: '',
    dataFields: {
        relations: [],
        countries: [],
        contact_types: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
}

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        fetchContactsSuccess(state, { payload }) {
            return { ...state, loading: false, contacts: payload, error: '' };
        },
        clearContact(state) {
            return { ...state, loadingBtn: false, loading: false, contactData: initialState.contactData, error: '' };
        },
        clearAlertContact(state) {
            return { ...state, updateContact: '', createContact: '', deleteContact: '', activeContact: '', error: '' };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilter: payload }
        },
        clearFilter(state) {
            return { ...state, searchFilter: initialState.searchFilter }
        },
    },
    extraReducers: {
      [startFetchContacts.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchContacts.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, contacts: payload, error: '' };
      },
      [startFetchContacts.rejected]: (state, { payload }) => {
        return { ...state, loading: false, contacts: initialState.contacts, error: payload };
      },

      [startFetchSearchFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchSearchFields.fulfilled]: (state, { payload }) => {
        return { ...state, searchFields: payload, loading: false, error: '' };
      },
      [startFetchSearchFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, searchFields: initialState.searchFields, error: payload };
      },

      [startCreateContact.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startCreateContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createContact: payload, error: '' };
      },
      [startCreateContact.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, createContact: '', error: payload };
      },

      [startUpdateContact.pending]: (state) => {
        return { ...state, loadingBtn: true, error: '' };
      },
      [startUpdateContact.fulfilled]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateContact: payload, error: '' };
      },
      [startUpdateContact.rejected]: (state, { payload }) => {
        return { ...state, loadingBtn: false, updateContact: '', error: payload };
      },

      [startFetchContact.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchContact.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, contactData: payload, error: '' };
      },
      [startFetchContact.rejected]: (state, { payload }) => {
        return { ...state, loading: false, contactData: '', error: payload };
      },

      [startDeleteContact.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, deleteContact: payload, error: '' };
      },
      [startDeleteContact.rejected]: (state, { payload }) => {
        return { ...state, loading: false, deleteContact: '', error: payload };
      },

      [startActiveContact.fulfilled]: (state, { payload }) => {
        return { ...state, loading: false, activeContact: payload, error: '' };
      },
      [startActiveContact.rejected]: (state, { payload }) => {
        return { ...state, loading: false, activeContact: '', error: payload };
      },

      [startFetchDataFields.pending]: (state) => {
        return { ...state, loading: true, error: '' };
      },
      [startFetchDataFields.fulfilled]: (state, { payload }) => {
        return { ...state, dataFields: payload, error: '' };
      },
      [startFetchDataFields.rejected]: (state, { payload }) => {
        return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
      },

      //Heading Functions
      [setSearch.fulfilled]: (state, { payload }) => {
        return { ...state, search: payload, currentPage: 1 }
      },
      [setCurrentPage.fulfilled]: (state, { payload }) => {
        return { ...state, currentPage: payload }
      },
      [setSort.fulfilled]: (state, { payload }) => {
        return { ...state, sortObj: payload }
      }

    },
});


export const contactActions = {
    ...contactSlice.actions,
    startFetchContacts,
    startFetchSearchFields,
    startCreateContact,
    startUpdateContact,
    startFetchContact,
    startDeleteContact,
    startActiveContact,
    startFetchDataFields,
    setSearch,
    setCurrentPage,
    setSort
}
export default contactSlice.reducer